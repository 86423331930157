import { dynamicallyLoadScript } from './utils.js';
import { waitConsent } from './onetrust.js';

export default function kameleoonInit() {
    const $elem = document.body;
    const config = {};
    let kameleoonLoaded = false;

    function loadKameleoon() {
        const kameleoonIframeURL = '/nav/extra/proxy?key=kameleoon_iframe';
        const kameleoonIframeOrigin = window.location.origin;
        const iframeNode = document.createElement('iframe');

        function kameleoonProcessMessageEvent(event) {
            if (kameleoonIframeOrigin === event.origin) {
                window.removeEventListener('message', kameleoonProcessMessageEvent);
                window.kameleoonExternalIFrameLoaded = true;
                if (window.Kameleoon) {
                    eval(event.data);
                    Kameleoon.Analyst.load();
                } else {
                    window.kameleoonExternalIFrameLoadedData = event.data;
                }
            }
        }

        if (location.href.indexOf(kameleoonIframeOrigin) === -1) {
            if (window.addEventListener) {
                window.addEventListener('message', kameleoonProcessMessageEvent, false);
            }
            iframeNode.src = kameleoonIframeURL;
            iframeNode.id = 'kameleoonExternalIframe';
            iframeNode.style =
                'float: left !important; opacity: 0.0 !important; width: 0px !important; height: 0px !important;';
            document.head.appendChild(iframeNode);
        }

        if (window.ANALYTICS?.dataLayerFilled) {
            dynamicallyLoadScript('/nav/extra/proxy?key=kameleoon');
        } else {
            document.addEventListener('datalayer_is_filled.tagcommander', function () {
                dynamicallyLoadScript('/nav/extra/proxy?key=kameleoon');
            });
        }
    }

    config.id = 'C0001';
    config.label = 'kameleoon';
    config.callback = function checkLoadKameleoon() {
        if (!kameleoonLoaded && $elem.dataset.isDcom && $elem.dataset.avecTagKameleoon && !$elem.dataset.isApp) {
            kameleoonLoaded = true;
            loadKameleoon();
        }
    };

    if ($elem.dataset.avecChargementKameleoonViaOnetrust) {
        waitConsent(config);
    } else if (!kameleoonLoaded && $elem.dataset.isDcom && $elem.dataset.avecTagKameleoon && !$elem.dataset.isApp) {
        kameleoonLoaded = true;
        loadKameleoon();
    }
}
